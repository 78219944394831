.ck-editor__top,
.ck-toolbar,
.ck-editor,
.ck-sticky-panel,
.ck-editor__main, 
.ck-editor__editable,
.ck-toolbar,
.ck-content, 
.editor,
.ck-media-form,
.ck-dropdown__panel {
    border-radius: .375rem !important;
}

.editor path {
    fill: var(--primary) !important;
}

.editor {
    height: 100%;
    width: 100%;
}

.editor-field,
.ck-dropdown__panel {
    border: 1px solid var(--secondary) !important;
}

.ck {
    box-shadow: none !important;
}

.ck-editor__main {
    height: calc(100% - 40px) !important;
}

.ck-sticky-panel,
.ck-editor__main, 
.ck-editor__editable,
.ck-toolbar,
.ck-content {
    border: none !important;
    outline: none !important;
}

.ck-heading-dropdown {
    display: none !important;
}

.ck-editor__main {
    color: black !important;
}

.ck-toolbar__separator:first-of-type {
    display: none !important;
}

.ck-dropdown__panel {
    overflow-y: auto;
}

.ck-editor,
.ck-content {
    width: 100% !important;
    max-height: 340px;
}

.ck-content {
    max-height: 300px;
}

.ck-button:hover:not(.ck-disabled) {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
  .ck-editor__top {
    display: none !important;
  }
}