.card-container{
    border-radius: var(--borderRadius);
    background-color: var(--backgroundLighter);
}

.card-header {
    border-top-left-radius: var(--borderRadius) !important;
    border-top-right-radius: var(--borderRadius) !important;
}

.card-content-wrapper {
    border-radius: var(--borderRadius);
}

.card-fit-content {
    width: fit-content;
}

@media (any-pointer) {
    .card-clickable-hover-styled:hover {
        background-color: var(--backgroundDarker) !important;
    }

    .card-clickable:hover {
        cursor: pointer;
    }

    .card-clickable-hover-styled:hover .card-header  {
        background-color: var(--muted);
    }
}