.expandable-item-icon-container {
    width: 66px;
}

.expandable-item-text {
    font-size: 14px;
    color: #002E7F !important;
}

@media (any-pointer) {
    .expandable-item-clickable:hover:not(.expandable-item-expanded) {
        cursor: pointer;
        background-color: var(--backgroundLighter);
    }

    .expandable-item-clickable:hover *  {
        color: #002E7F !important;
    }

    .expandable-item-clickable:hover .expandable-item-text  {
        font-weight: bold;
    }
}