@keyframes userMenuAppear {
    from {
        max-width: 0;
        min-width: 0;
        opacity: 0;
    }
}

.user-menu-avatar {
    z-index: 999 !important;
}

.user-menu {
    top: 0;
    right: 0;
    z-index: 998;
    border-top-right-radius: 25px !important;
    border-radius: var(--borderRadius);
    box-shadow: 0px 0px 8px var(--shadow);
    background-color: white;
    min-width: 250px;
    max-width: 450px;
    max-height: 450px;
    overflow-y: auto;
    animation: userMenuAppear 0.1s ease;

}

.user-menu-item {
    width: 100%;
}

.user-menu-item-icon {
    width: 48px;
}

@media screen and (any-pointer) {
    .user-menu-container:hover {
        cursor: pointer;
    }

    .user-menu-item:hover {
        cursor: pointer;
        background-color: var(--mutedLight);
    }

    .user-menu-item:hover * {
        color: var(--primary) !important;
    }
}