.response-times-grid,
.productivity-grid {
  display: grid;
  grid-gap: 1rem;
}

.productivity-grid {
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

.response-times-grid {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}