@keyframes modalFormBackgroundAppear {
    from {
        background-color: transparent;
    }
}

@keyframes modalFormAppear {
    from {
        transform: scale(0);
        opacity: 0; 
    }
}

.dialog-header {
    min-height: 45px;
    margin-bottom: 20px;
}

.modal-form-background {
    background-color: #002E7F;
    opacity: 0.4;
}

.modal-form {
    background-color: white;
    transition: all 0.1s ease;
    max-height: 98vh;
    height: fit-content;
    overflow: hidden;
    animation: modalFormAppear 0.3s ease;
}

.modal-form-content {
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-form,
.modal-form-container {
    z-index: 800;
}

.modal-form {
    border-radius: var(--borderRadius);
    box-shadow: 0px 0px 8px var(--shadow);
}

@media screen and (max-width: 1000px) {
    .modal-form {
        width: 96vw !important;
    }
}